import { BaseResponse, C6BaseResponse } from '@/types/API'
import request, { c6Request, fileRequest } from '@/utils/request'
import SparkMD5 from 'spark-md5'

/** 上传文件并获取返回的guid */
export async function uploadFileGetFileInfo(file: File, uploadPath: string = '/Test/') {
  const resp = await uploadFile(file, uploadPath)
  return resp.data.data.upFileList[0]
}

/** 获取文件下载url */
export function getFileDownloadUrl(fileId: number) {
  return fileRequest.get<BaseResponse<string>>(`file/download/${fileId}`)
}

/** 获取文件基础url */
export function getFileBasePreviewUrl() {
  return fileRequest.get<BaseResponse<string>>(`file/base-preview-url`)
}

/** 绑定上传的文件 */
export function bindUploadedFile(data: BindUploadedFileReq) {
  return c6Request.post<C6BaseResponse>(`backstage/file/saveFile`, data)
}

/** 删除绑定的文件 */
export function deleteBindUploadedFile(fileIds: number[]) {
  return c6Request.post<C6BaseResponse>(`backstage/file/deleteBatchByIds`, fileIds)
}

/** 上传文件 */
let progressElem: HTMLElement | null = null

export async function uploadFile(file: File, path: string = '/', onUploadProgress?: (evt: ProgressEvent) => void) {

  var maxChunkRetries = 3; //  并发上传数，默认 3
  var fileFullName = file.name;
  var chunkSize = 1024 * 1024 * 1000;//切片大小
  var fileMd5 = "";

  // 所有请求
  const requests: Promise<BaseResponse<UploadFileResponse>>[] = []

  const calculateResult = await calculate(file)

  fileMd5 = calculateResult;
  var upLoadInfo = {} as UploadInfo;
  upLoadInfo.identifier = fileMd5;//md5码
  upLoadInfo.filePath = path;
  upLoadInfo.isDir = 0;
  upLoadInfo.filename = fileFullName;//文件名
  upLoadInfo.relativePath = fileFullName;// 文件名
  upLoadInfo.extendName = fileFullName.substring(fileFullName.lastIndexOf(".") + 1);//扩展名
  upLoadInfo.totalSize = file.size;//总大小
  upLoadInfo.chunkSize = chunkSize;//切片大小
  upLoadInfo.totalChunks = Math.ceil(upLoadInfo.totalSize / upLoadInfo.chunkSize);//所有切片

  for (var _chunks = 1; upLoadInfo.totalChunks >= _chunks; _chunks++) {
    var now = JSON.parse(JSON.stringify(upLoadInfo));
    if (upLoadInfo.totalChunks > _chunks) {
      now.currentChunkSize = chunkSize;

    }
    else {
      now.currentChunkSize = upLoadInfo.totalSize - ((_chunks - 1) * upLoadInfo.chunkSize);//当前切片大小

    }

    now.chunkNumber = _chunks;
    var start = (_chunks-1) * upLoadInfo.chunkSize;
    var end = Math.min(upLoadInfo.totalSize, start + upLoadInfo.chunkSize);
    now.files = file.slice(start, end);
    requests.push(doUploadFile(now, onUploadProgress))
  }

  // upLoadInfo.currentChunkSize = 0;//当前切片大小
  // upLoadInfo.chunkNumber = 0;//切片数量
  // upLoadInfo.uploadTime = 0;//上传时间

  return new Promise<BaseResponse<UploadFileResponse>>(async (resolve, reject) => {
    try {
      const results = await Promise.all(requests)
      if (results.length === 0) {
        reject(new Error('没有返回任何数据'))
      }
      for (let i = 0; i < results.length; i++) {
        console.log(results)
        const r = results[i]
        if (r && r.data && r.data.data && Array.isArray(r.data.data.upFileList) && r.data.data.upFileList.length > 0) {
          resolve(r)
          return
        }
      }
      reject(new Error('没有获取到上传图片的id'))
      
    } catch (e) {
      reject(e)
    }
  })

}

function doUploadFile(upLoadData: UploadInfo, onUploadProgress?: (evt: ProgressEvent) => void) {
  var fd = new FormData();
  Object.keys(upLoadData).forEach((key) => {
    fd.append(key, upLoadData[key]);
  });
  return fileRequest.post('file/upload', fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress
  }) as Promise<BaseResponse<UploadFileResponse>>
}

function uploadProgress(evt: ProgressEvent) {
  if (!progressElem) return
  if (evt.lengthComputable) {
    var percentComplete = Math.round(evt.loaded * 100 / evt.total);
    progressElem.innerHTML = percentComplete.toString() + '%';
  }
  else {
    progressElem.innerHTML = 'unable to compute';
  }
}

function calculate(file: File) {
  return new Promise<string>(function (resolve, reject) {
    var fileReader = new FileReader(),
      blobSlice = File.prototype.slice,
      
      chunkSize = 2097152,
      // read in chunks of 2MB
      chunks = Math.ceil(file.size / chunkSize),
      currentChunk = 0,
      spark = new SparkMD5();
      
    fileReader.onload = function (e) {
    
      spark.appendBinary((e.target as FileReader).result as string); // append binary string
      currentChunk++;
      if (currentChunk < chunks) {
        loadNext();
      
      }
      else {
        var md5_ = spark.end();   
        resolve(md5_) ;
      }
    };

    function loadNext() {
    
      var start = currentChunk * chunkSize,
        end = start + chunkSize >= file.size ? file.size : start + chunkSize;
        fileReader.readAsBinaryString(blobSlice.call(file, start, end));
      
    };

    loadNext();

  })
}

export interface UploadFileResponse extends BaseResponse {
  data: {
    needMerge: boolean,
    skipUpload: boolean,
    timeStampName: any,
    upFileList: UpFileListItem[],
    uploaded: any
  }
}

export interface UpFileListItem {
  extendName: string,
  fileId: number,
  fileName: string,
  filePath: string,
  userFileId: number,
  userId: number,
  userFileGuid: string
}

export interface UploadInfo {
  identifier: string;
  filePath: string;
  isDir: number;
  filename: string;
  relativePath: string;
  extendName: string;
  totalSize: number;
  chunkSize: number;
  totalChunks: number;
  [key: string]: any
}

export interface BindUploadedFileReq {
  sourceId: number;
  sourceTypeId: number;
  fileTypeId: number;
  fileId?: number;
  lat?: number;
  lon?: number;
  fileGuid?: string;
}

export interface BindFileInfo {
  createdTime: string,
  fileGuid: string,
  fileID: number,
  fileTypeID: number,
  id: number,
  latitude: number,
  longitude: number,
  sourceID: number,
  sourceTypeID: number
}

export interface FileMetaData {
  fileGuid: string,
  fileId: number,
  fileName?: string,
  lat?: number,
  lon?: number
}

// export interface DeleteBindUploadedFileReq {
//   fileIds: number[]
// }